import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectionSource from "./ProjectionSource";
import PromptScreen from "./PromptScreen";
import './css/base.css';
import {Helmet} from "react-helmet";

export default function App() {
  return (
    <BrowserRouter>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BlueGhost Futurescope</title>
            <meta name="description" content="Nahlédněte do budoucnosti" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link href="https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" />
            <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.PUBLIC_URL}/favicons/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/favicons/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/favicons/favicon-16x16.png`} />
            <link rel="manifest" href={`${process.env.PUBLIC_URL}/favicons/site.webmanifest`} />
            <link rel="mask-icon" href={`${process.env.PUBLIC_URL}/favicons/safari-pinned-tab.svg`} color="#5bbad5" />
        </Helmet>
        <Routes>
            <Route index element={<PromptScreen />} />
            <Route path="projection" element={<ProjectionSource />} />
        </Routes>
    </BrowserRouter>
  );
}

