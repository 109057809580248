import React, {useEffect, useState} from "react";
import PrimaryButton from "./PrimaryButton";
import Papa from 'papaparse';

const SHEET_ID = '1WRWnWz-dkr-7ytlGwv7FStgvYuod3U3OvxgC5SmoAx4';
const SHEET_TAB_NAME = 'Sheet1';
function PromptScreen() {

    const [formData, setFormData] = useState({
        prompt: '',
    });

    // Make.com connection
    const [submittedToWebhook, setSubmittedToWebhook] = React.useState(false);
    const [submitError, setSubmitError] = useState({name: false, email: false});

    // Form data handling
    const submitData = async (event) => {
        event.preventDefault()

        // Validate form
        let errors = {prompt: false};
        if (!formData.prompt) {
            errors.prompt = true;
        }
        if (errors.prompt) {
            setSubmitError(errors);
            return;
        }
        setSubmitError({prompt: false}); // reset after successful submit

        // Send data by Webhook to make.com
        const webhookUrl = 'https://hook.eu1.make.com/rzuptpgjt7c9nkjurm7dn14sri82hao9';
        const response = await fetch(webhookUrl, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setSubmittedToWebhook(true);
    };

    // Google Sheets connection
    // Google Sheets connection
    useEffect(() => {
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:csv&sheet=${SHEET_TAB_NAME}`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const csvData = await response.text();

                // Použití PapaParse k parsování CSV dat
                const parsedData = Papa.parse(csvData, {
                    header: false, // Zde můžete nastavit, zda chcete data s hlavičkou nebo bez
                    skipEmptyLines: true // Přeskočí prázdné řádky
                });

                // nyní můžete pracovat s parsedData.data jako s polem polí
                const validDataRows = parsedData.data.filter(row => row.length && row[0]);
                const lastRow = validDataRows[validDataRows.length - 1];
                const promptFromTable = lastRow[2].replace(/^"(.*)"$/, '$1'); // Removes quotes from the string

                console.log('Prompt from table: ', promptFromTable);
                console.log('Prompt from form: ', formData.prompt);

                if (promptFromTable === formData.prompt && submittedToWebhook) {
                    console.log('Prompt is the same, no need to update');
                    setSubmittedToWebhook(false);
                    setFormData(prevState => ({
                        ...prevState,
                        'prompt': ''
                    }));
                }
            } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const intervalId = setInterval(fetchSheetData, 2000); // 2 seconds
    return () => clearInterval(intervalId);
}, [formData.prompt, submittedToWebhook]); // Dependency array

    const handleChange = (e) => {
        const prompt = e.target.name;
        const value = e.target.value;

        setFormData(prevState => ({
            ...prevState,
            [prompt]: value
        }));
    };

    // Loading message changing
    const loadingMessages = [
        "Věštím budoucnost",
        "Může to trvat asi minutu",
        "Hledám křišťálovou kouli",
        "Shromažďuji data",
        "Počítám predikce",
        "Analyzuji velká data",
        "Zkoumám kávovou sedlinu",
        "Odlévám olovo",
        "Extrapoluji data",
        "Vybírám prediktivní model",
        "Propočítávám konstalace hvězd",
        "Listuji v kouzelnických knihách",
        "Míchám karty",
        "Počítám lineární regrese",
        "Počítám polynomické regrese",];
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        if (submittedToWebhook) {
            const intervalId = setInterval(() => {
                setCurrentMessageIndex(() => {
                    // Generate random index
                    const randomIndex = Math.floor(Math.random() * loadingMessages.length);

                    // If the random index is the same as the current message index, return the next index
                    if (randomIndex === currentMessageIndex) {
                        return (randomIndex + 1) % loadingMessages.length;
                    }
                    return randomIndex;
                });
            }, 6000);
            return () => clearInterval(intervalId);
        }
    }, [submittedToWebhook, currentMessageIndex, loadingMessages]);


    return (
        <div className="promptScreenContainer">

        {submittedToWebhook ? (
            <div className="promptBox formSent">
                <p className="codeRow">{loadingMessages[currentMessageIndex]}
                    <span className="dotOne">.</span>
                    <span className="dotTwo">.</span>
                    <span className="dotThree">.</span>
                </p>
            </div>
        ) : (
            <div className="promptBox">
                <p className="codeRow">Co bude nabízet nejpopulárnější online služba v roce 2043?</p>
                <form>
                    <div className={`textField ${submitError.prompt ? 'submitError' : ''}`}>
                        <label htmlFor="prompt" className="promptLabel codeRow">Vaše vize<span className="doubleDot">:</span></label>
                        <input
                            type="text"
                            id="prompt"
                            name="prompt"
                            placeholder=""
                            value={formData.prompt}
                            onChange={handleChange}
                            autoComplete="off"
                            autoFocus
                        />
                    </div>
                    {formData.prompt.trim().length > 0 && (
                        <div>
                            <p className="codeRow">------↓↓↓------</p>
                            <div className="bgIcon"></div>
                            <PrimaryButton
                                value="Ukaž budoucnost"
                                type="submit"
                                submitHandle={submitData}
                            >
                            </PrimaryButton>
                        </div>
                    )}
                </form>
            </div>
        )}
        </div>
    );
}

export default PromptScreen;