import React, { useState, useEffect } from 'react';

const SHEET_ID = '1WRWnWz-dkr-7ytlGwv7FStgvYuod3U3OvxgC5SmoAx4';
const SHEET_TAB_NAME = 'Sheet1';

const ProjectionSource = () => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const switchSound = `${process.env.PUBLIC_URL}/switchSound.mp3`;

    const switchAudio = new Audio(switchSound);
    const playSound = () => {
        switchAudio.play();
    };

    useEffect(() => {
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:csv&sheet=${SHEET_TAB_NAME}`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const csvData = await response.text();
                const dataArray = csvData.split('\n').map(row => row.split(','));
                const validDataRows = dataArray.filter(row => row.length && row[0]);
                const lastRow = validDataRows[validDataRows.length - 1];
                const newImageUrl = lastRow[0].replace(/^"(.*)"$/, '$1'); // Removes quotes from the string

                if (newImageUrl !== imageUrl) {
                    setLoading(true);
                    playSound();

                    // Start loading new image into cache
                    const img = new Image();
                    img.src = newImageUrl;
                    img.onload = () => {
                        // The image is now loaded into the browser's cache
                        // Update the state to display the new image, this will happen almost instantly for the user
                        setTimeout(() => {
                            setImageUrl(newImageUrl);
                        }, 1000);

                        // Remove 'imageLoading' after delay
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);
                    };
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        const intervalId = setInterval(fetchSheetData, 2000); // 2 seconds
        return () => clearInterval(intervalId);
    }, [imageUrl]); // Dependency array

    return (
        <div className="projectionContainer">
            <div className={`imageCover ${loading ? 'imageLoading' : ''}`}>
                {imageUrl && <img src={imageUrl} alt="Future Vision" />}
            </div>
        </div>
    );
};

export default ProjectionSource;