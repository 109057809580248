import React, { useRef } from "react";

function PrimaryButton(props) {
    const btnRef = useRef(null);

    const handleMouseMove = (e) => {
        if (btnRef.current) {
            var x = e.clientX - e.currentTarget.getBoundingClientRect().left;
            var y = e.clientY - e.currentTarget.getBoundingClientRect().top;
            e.currentTarget.style.setProperty('--x', x + 'px');
            e.currentTarget.style.setProperty('--y', y + 'px');
        }
    };

    return (
        <button
            className="primaryButton"
            onMouseMove={handleMouseMove}
            ref={btnRef}
            onClick={props.submitHandle}
            type={props.type || "button"}
        >
            <span>{props.value}</span>
        </button>
    );
}

export default PrimaryButton;